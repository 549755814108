import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import PythonWebImg from "../static/img/python-web.jpg";
import PythonIcon from "../static/img/python-icon.png";
import AccordionItem from "../component/Courses/AccordionItem";
import ContactForm from "../component/Courses/ContactForm";
import MetaTags from "../component/MetaTags";
import Price from "../component/Price";

const pythonAdvanceBasicCourse = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  return (
    <>
      <Navbar />
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <section id="courses-sec" className="course-banner">
          <div className="language-content">
            <h1 align="center">THE BEST ONLINE PYTHON COURSE</h1>
            <p align="center">
              {
                "Our comprehensive Python course is designed to provide you with a solid foundation in this powerful and versatile programming language. Whether you're a beginner with no prior coding experience or an experienced developer looking to enhance your skills, our program caters to learners of all levels."
              }
              <br />
              {
                "Don't miss out on this incredible opportunity to enhance your programming skills and unlock endless possibilities. Join our Python Summer Internship Training Program today and embark on an exciting journey toward becoming a proficient Python developer. Let's code your future together!"
              }
            </p>
            <Price buy={true} inUrl={'test_7sI7wv0JU0U54j63ck'} usUrl={'test_7sIg3164e32deXKeV3'} />
          </div>
          <div className="japanese-form">
            <ContactForm />
          </div>
        </section>

        <h3 className="courses__joinUs"></h3>
        <section id="courses__about" className="courses__about">
          <h3 className="light-text text-center">
            ABOUT{" "}
            <span className="highlighted-text">PYTHON ONLINE TRAINING</span>
          </h3>
          <div className="container pt-4 d-flex flex-column gap-5">
            <div className="row">
              <div className="col-sm-6 d-flex justify-content-center">
                <img src={PythonWebImg} alt="" className="courses__about-img" />
              </div>
              <div className="col-sm-6 d-flex align-items-center mt-4 mt-md-0">
                <div className="courses__about__content">
                  <p>
                    {
                      "Our online Python course is specifically designed to provide a comprehensive learning experience for individuals interested in mastering Python programming. Here are some key features and details about our course."
                    }
                  </p>
                  <p>
                    {
                      "Upon completing our Python Summer Internship Training Program, you'll walk away with a strong command of Python, a portfolio of impressive projects, and the confidence to tackle real-world coding challenges. Whether you're looking to secure an internship, launch a startup, or advance your current career, our program equips you with the skills to thrive in today's tech-driven world."
                    }
                  </p>
                </div>
              </div>
            </div>

            <div className="row course-highlights">
              <h3 className="ms-3 font-weight-bolder">Program Highlights</h3>
              <div className="col-sm-12 col-md-12 mt-2">
                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Learn Python from industry experts during a focused
                        one-month training program.
                      </span>
                    </p>
                  </div>{" "}
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Establish a solid groundwork in fundamental coding
                        principles.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Enhance problem-solving and analytical skills through
                        interactive learning.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Syllabus designed to align with industry requirements
                        and job market demands.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Receive assistance in finding internships that align
                        with your Python skills.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Beginner to expert level coaching to cater for learners
                        at all stages.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Obtain a valid certificate upon completion of the
                        course.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        Access affordable fee options to make the course
                        accessible.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Benefit from a comprehensive curriculum covering various
                        Python topics.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Engage in an interactive learning environment with
                        active participation.
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <p className="course-highlights__content d-flex gap-2">
                      <span>📌</span>
                      <span>
                        {" "}
                        Gain career advancement opportunities through networking
                        and industry connections.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row course-syllabus">
              <h3 className="light-text text-center">
                What <span className="highlighted-text">You Will Learn</span>
              </h3>
              <div className="d-flex flex-column flex-lg-row gap-5 mt-4">
                <ul className="course-syllabus__list">
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 1: Python Fundamentals"
                  >
                    <ul>
                      <li>Introduction to Python and its features</li>
                      <li>Setting up the Python development environment</li>
                      <li>Python syntax, variables, and data types</li>
                      <li>Control flow statements (if, else, loops)</li>
                      <li>Functions and modules in Python</li>
                      <li>
                        Error handling and exceptions in Python programming
                      </li>
                    </ul>
                  </AccordionItem>
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 2: Object-Oriented Programming (OOP) in Python"
                  >
                    <motion.ul>
                      <li>Introduction to OOP concepts in Python</li>
                      <li>Classes, objects, and methods</li>
                      <li>Inheritance and polymorphism in Python</li>
                      <li>Encapsulation and data abstraction</li>
                      <li>
                        Advanced OOP topics such as decorators and static
                        methods
                      </li>
                    </motion.ul>
                  </AccordionItem>
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 3: Python Libraries and Modules"
                  >
                    <ul>
                      <li>
                        Introduction to well-known Python libraries, including
                        NumPy, Pandas, and Matplotlib.
                      </li>
                      <li>Working with numerical data using NumPy arrays</li>
                      <li>Data manipulation and analysis with Pandas</li>
                      <li>Data visualization using Matplotlib</li>
                      <li>Introduction to web scraping using Beautiful Soup</li>
                      <li>Working with databases using SQLite or MySQL</li>
                    </ul>
                  </AccordionItem>
                  <AccordionItem
                    className="course-syllabus__list-item"
                    title="Week 4: Intermediate Python Topics"
                  >
                    <ul>
                      <li>
                        File handling and input/output operations in Python
                      </li>
                      <li>
                        Regular expressions for pattern matching in Python
                      </li>
                      <li>Working with APIs and handling JSON data</li>
                      <li>
                        Introduction to web development using Flask or Django
                      </li>
                      <li>
                        Introduction to machine learning with Scikit-learn
                      </li>
                      <li>
                        Testing and debugging techniques in Python programming
                      </li>
                    </ul>
                  </AccordionItem>
                  {/* <div>
                      Please note that this syllabus provides an overview of the topics covered in each week, and specific subtopics or additional concepts may be included based on the course requirements and duration.
                  </div> */}
                </ul>
                <img
                  src={PythonIcon}
                  style={{ justifySelf: "start" }}
                  alt=""
                  className=""
                />
              </div>
            </div>
          </div>
          <section
            id="services"
            className="advance"
            style={{ marginTop: "4rem" }}
          >
            <div className="container">
              1
              <header className="section-header">
                <h3 className="light-text">
                  {" "}
                  WHY{" "}
                  <span className="highlighted-text">
                    {" "}
                    KODERBOX TECHNOLOGIES?
                  </span>{" "}
                </h3>
              </header>
              <br />
              <div className="">
                <div
                  className="col-md-12  wow bounceInUp "
                  data-wow-duration="1.4s"
                >
                  <div className="box blank_service">
                    <div className="row">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                We believe that learning should be accessible to
                                everyone. Our course offers an affordable fee
                                structure, making quality Python education
                                within reach for aspiring learners.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                We prioritize practical training to ensure you
                                acquire hands-on experience. Through real-world
                                projects, coding exercises, and interactive
                                sessions, you will gain the skills and
                                confidence needed to excel in React JS
                                development.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                Our online python course covers a wide range of
                                Python topics, ensuring that you gain a holistic
                                understanding of the language and its
                                applications. You'll learn about variables, data
                                types, control flow, functions, object-oriented
                                programming, and more.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp mb-2"
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                Choosing our online Python course opens up
                                career advancement opportunities. You'll have
                                the chance to network with professionals in the
                                industry, expanding your connections and opening
                                doors for future career prospects.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>

          {/* <section className="join container">
            <div className="join__layer">
              <div className="join__layer-1">
                <h1>
                  Take the first step towards a successful career in tech era
                  with us.
                </h1>
                <p>
                  Join our python Training program and find the potential to
                  learn dynamic and interactive framework using this powerful
                  language.
                </p>
              </div>
              <div className="join__layer-2">
                <h2>Contact</h2>
                <div className="join__layer-2--form-control">
                  <label for="full-name">Full Name</label>
                  <input type="text" id="full-name" name="full-name" />
                </div>
                <div className="join__layer-2--form-control">
                  <label for="email">Email</label>
                  <input type="email" id="email" name="email" />
                </div>
                <button>Contact Now</button> */}
          {/* <p className="text-xs text-gray-500 mt-3">
                  Literally you probably haven't heard of them jean shorts.
                </p> */}
          {/* </div>
            </div>
          </section> */}
        </section>
      </motion.div>
      <Footer />
      <MetaTags title={"Python | Koderbox"} />
    </>
  );
};

export default pythonAdvanceBasicCourse;
